export const eventConstants = {
    getAllEventsRequest: "GET_ALL_EVENTS_REQUEST",
    getAllEventsSuccess: "GET_ALL_EVENTS_SUCCESS",
    getAllEventsFailure: "GET_ALL_EVENTS_FAILURE",

    getActiveEventsRequest: "GET_ACTIVE_EVENTS_REQUEST",
    getActiveEventsSuccess: "GET_ACTIVE_EVENTS_SUCCESS",
    getActiveEventsFailure: "GET_ACTIVE_EVENTS_FAILURE",

    getUpComingEventsRequest: "GET_UPCOMING_EVENTS_REQUEST",
    getUpComingEventsSuccess: "GET_UPCOMING_EVENTS_SUCCESS",
    getUpComingEventsFailure: "GET_UPCOMING_EVENTS_FAILURE",

    getPastEventsRequest: "GET_PAST_EVENTS_REQUEST",
    getPastEventsSuccess: "GET_PAST_EVENTS_SUCCESS",
    getPastEventsFailure: "GET_PAST_EVENTS_FAILURE",

    createEventRequest: "CREATE_EVENT_REQUEST",
    createEventSuccess: "CREATE_EVENT_SUCCESS",
    createEventFailure: "CREATE_EVENT_FAILURE",

    getEventRequest: "GET_EVENT_REQUEST",
    getEventSuccess: "GET_EVENT_SUCCESS",
    getEventFailure: "GET_EVENT_FAILURE",

    updateEventRequest: "UPDATE_EVENT_REQUEST",
    updateEventSuccess: "UPDATE_EVENT_SUCCESS",
    updateEventFailure: "UPDATE_EVENT_FAILURE",

    deleteEventRequest: "DELETE_EVENT_REQUEST",
    deleteEventSuccess: "DELETE_EVENT_SUCCESS",
    deleteEventFailure: "DELETE_EVENT_FAILURE",

    getUploadedImagesRequest: "GET_UPLOADED_IMAGES_REQUEST",
    getUploadedImagesSuccess: "GET_UPLOADED_IMAGES_SUCCESS",
    getUploadedImagesFailure: "GET_UPLOADED_IMAGES_FAILURE",

    getApprovedImagesRequest: "GET_APPROVED_IMAGES_REQUEST",
    getApprovedImagesSuccess: "GET_APPROVED_IMAGES_SUCCESS",
    getApprovedImagesFailure: "GET_APPROVED_IMAGES_FAILURE",

    getRejectedImagesRequest: "GET_REJECTED_IMAGES_REQUEST",
    getRejectedImagesSuccess: "GET_REJECTED_IMAGES_SUCCESS",
    getRejectedImagesFailure: "GET_REJECTED_IMAGES_FAILURE",

    imageUploadRequest: "IMAGE_UPLOAD_REQUEST",
    imageUploadSuccess: "IMAGE_UPLOAD_SUCCESS",
    imageUploadFailure: "IMAGE_UPLOAD_FAILURE",

    imageApproveRequest: "IMAGE_APPROVE_REQUEST",
    imageApproveSuccess: "IMAGE_APPROVE_SUCCESS",
    imageApproveFailure: "IMAGE_APPROVE_FAILURE",

    imageRejectRequest: "IMAGE_REJECT_REQUEST",
    imageRejectSuccess: "IMAGE_REJECT_SUCCESS",
    imageRejectFailure: "IMAGE_REJECT_FAILURE",

    imageRemoveRequest: "IMAGE_REMOVE_REQUEST",
    imageRemoveSuccess: "IMAGE_REMOVE_SUCCESS",
    imageRemoveFailure: "IMAGE_REMOVE_FAILURE",

    getByFilterRequest: "GET_BY_FILTER_REQUEST",
    getByFilterSuccess: "GET_BY_FILTER_SUCCESS",
    getByFilterFailure: "GET_BY_FILTER_FAILURE",

    getAllRSVPRequest: "GET_ALL_RSVP_REQUEST",
    getAllRSVPSuccess: "GET_ALL_RSVP_SUCCESS",
    getAllRSVPFailure: "GET_ALL_RSVP_FAILURE",

    getImagesInteractionRequest: "GET_IMAGE_INTERACTION_REQUEST",
    getImagesInteractionSuccess: "GET_IMAGE_INTERACTION_SUCCESS",
    getImagesInteractionFailure: "GET_IMAGE_INTERACTION_FAILURE",

    getCommentListRequest: "GETALL_COMMENTS_REQUEST",
    getCommentListSuccess: "GETALL_COMMENTS_SUCCESS",
    getCommentListFailure: "GETALL_COMMENTS_FAILURE",

    getRepliesListRequest: "GETALL_REPLIES_REQUEST",
    getRepliesListSuccess: "GETALL_REPLIES_SUCCESS",
    getRepliesListFailure: "GETALL_REPLIES_FAILURE",

    getReportListRequest: "GETALL_REPORTS_REQUEST",
    getReportListSuccess: "GETALL_REPORTS_SUCCESS",
    getReportListFailure: "GETALL_REPORTS_FAILURE",

    createCommentOrReplyRequest: "ADD_COMMENT_OR_REPLY_REQUEST",
    createCommentOrReplySuccess: "ADD_COMMENT_OR_REPLY_SUCCESS",
    createCommentOrReplyFailure: "ADD_COMMENT_OR_REPLY_FAILURE",

    removeCommentOrReplyRequest: "REMORE_COMMENT_OR_REPLY_REQUEST",
    removeCommentOrReplySuccess: "REMORE_COMMENT_OR_REPLY_SUCCESS",
    removeCommentOrReplyFailure: "REMORE_COMMENT_OR_REPLY_FAILURE",

    userInteractionRequest: "USER_INTERACTION_REQUEST",
    userInteractionSuccess: "USER_INTERACTION_SUCCESS",
    userInteractionFailure: "USER_INTERACTION_FAILURE",

    addReportRequest: "ADD_REPORT_REQUEST",
    addReportSuccess: "ADD_REPORT_SUCCESS",
    addReportFailure: "ADD_REPORT_FAILURE",
   
    updateCommentOrReplyRequest: "UPDATE_COMMENT_OR_REPLY_REQUEST",
    updateCommentOrReplySuccess: "UPDATE_COMMENT_OR_REPLY_SUCCESS",
    updateCommentOrReplyFailure: "UPDATE_COMMENT_OR_REPLY_FAILURE",
}