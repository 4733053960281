import { eventConstants } from "../actions/constants/eventConstant";

export const getAllEvents = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getAllEventsRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getAllEventsSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isListed: true,
                allEvents: action.payload
            }
        case eventConstants.getAllEventsFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const getAllRSVPresponse = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getAllRSVPRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getAllRSVPSuccess:
            console.log("rsvp", action.payload)
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isListed: true,
                rsvpResponse: action.payload
            }
        case eventConstants.getAllRSVPFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const getActiveEvents = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getActiveEventsRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getActiveEventsSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isListed: true,
                isError: false,
                activeEvents: action.payload
            }
        case eventConstants.getActiveEventsFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const getUpcomingEvents = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getUpComingEventsRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getUpComingEventsSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isListed: true,
                isError: false,
                upComingEvents: action.payload
            }
        case eventConstants.getUpComingEventsFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const getPastEvents = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getPastEventsRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getPastEventsSuccess:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                isListed: true,
                isError: false,
                pastEvents: action.payload
            }
        case eventConstants.getPastEventsFailure:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const CreateEvent = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.createEventRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.createEventSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isCreated: true,
                isError: false,
                event: action.payload
            }
        case eventConstants.createEventFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const getEvent = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getEventRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getEventSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isGet: true,
                event: action.payload
            }
        case eventConstants.getEventFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateEvent = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.updateEventRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.updateEventSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isUpdated: true,
                event: action.payload
            }
        case eventConstants.updateEventFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const deleteEvent = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.deleteEventRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.deleteEventSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isDeleted: true,
                event: action.payload
            }
        case eventConstants.deleteEventFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const uploadedImages = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getUploadedImagesRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getUploadedImagesSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isUpload: true,
                uploadedImages: action.payload
            }
        case eventConstants.getApprovedImagesFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const approvedImages = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getApprovedImagesRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getApprovedImagesSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isApprove: true,
                approvedImages: action.payload,
            }
        case eventConstants.getApprovedImagesFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const rejectedImages = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getRejectedImagesRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getRejectedImagesSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isReject: true,
                rejectedImages: action.payload
            }
        case eventConstants.getRejectedImagesFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const imageUpload = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.imageUploadRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.imageUploadSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isUploaded: true,
            }
        case eventConstants.imageUploadFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const imageApprove = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.imageApproveRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.imageApproveSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isApproved: true,
            }
        case eventConstants.imageApproveFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const imageReject = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.imageRejectRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.imageRejectSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isRejected: true,
            }
        case eventConstants.imageRejectFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const imageRemove = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.imageRemoveRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.imageRemoveSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isRemoved: true,
            }
        case eventConstants.imageRemoveFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const getByFilterEvent = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getByFilterRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getByFilterSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                myEvents: action.payload
            }
        case eventConstants.getByFilterFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const getInteractions = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getImagesInteractionRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getImagesInteractionSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isInteracted:true,
                interaction: action.payload
            }
        case eventConstants.getImagesInteractionFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const commentOrReply = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.createCommentOrReplyRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.createCommentOrReplySuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isAdded: true,
                commentOrReply: action.payload
            }
        case eventConstants.createCommentOrReplyFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const deleteCommentOrReply = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.removeCommentOrReplyRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.removeCommentOrReplySuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isRemoved: true,
            }
        case eventConstants.removeCommentOrReplyFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const comments = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getCommentListRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getCommentListSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isListed: true,
                comments: action.payload
            }
        case eventConstants.getCommentListFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const replies = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getRepliesListRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getRepliesListSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isListed: true,
                replies: action.payload
            }
        case eventConstants.getRepliesListFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const reports = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.getReportListRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.getReportListSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isListed: true,
                reports: action.payload
            }
        case eventConstants.getReportListFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const userInteraction = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.userInteractionRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.userInteractionSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isInteracted: true,
                interaction: action.payload
            }
        case eventConstants.userInteractionFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const addReport = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.addReportRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.addReportSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isAdded: true,
                Report: action.payload
            }
        case eventConstants.addReportFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}

export const updateCommentOrReply = (state = {}, action: any) => {
    switch (action.type) {
        case eventConstants.createCommentOrReplyRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case eventConstants.createCommentOrReplySuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isAdded: true,
                commentOrReply: action.payload
            }
        case eventConstants.createCommentOrReplyFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
            }
        default:
            return state;
    }
}