import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { PrivateRoute } from './components/privateRoute';
import Spinner from './components/spinner';
import { clubActions } from './actions/clubAction';
import { useDispatch } from 'react-redux';
import Loader from './components/loader';
import { RoleRoute } from './components/roleRoute';
import { Role } from './actions/constants/common';
import AccessDenied from './pages/layout/accessDenied';
import JoinClub from './pages/club/joinClub';
import env from './services/env';
import { userConstants } from './actions/constants/userConstant';
import IdleTimer from './components/sessionTimer';
import { getCurrentTime, getIsTest, getIsValidSession, getRenewalTimeBeforeExpiry } from './services/helper';
import { authActions } from './actions/authAction';
import Rsvp from './pages/event/rsvp';

const Layout = lazy(() => import('./pages/layout/layout'));
const Home = lazy(() => import('./pages/home/home'));
const Login = lazy(() => import('./pages/account/login'));
const ForgotPassword = lazy(() => import('./pages/account/forgotPassword'));
const ResetPassword = lazy(() => import('./pages/account/resetPassword'));
const ClubList = lazy(() => import('./pages/club/clubList'));
const ClubView = lazy(() => import('./pages/club/clubView'));
const ClubEdit = lazy(() => import('./pages/club/clubView'));
const ClubCreate = lazy(() => import('./pages/club/clubCreate'));
const ClubSetup = lazy(() => import('./pages/club/clubSetup'));
const PasswordSetup = lazy(() => import('./pages/club/passwordSetup'));
const RegistrationComplete = lazy(() => import('./pages/club/registrationComplete'));

const MyClub = lazy(() => import('./pages/club/myClub'));
const EventList = lazy(() => import('./pages/event/eventList'));
const EventView = lazy(() => import('./pages/event/eventView'));
const EventEdit = lazy(() => import('./pages/event/eventView'));
const CreateEvent = lazy(() => import('./pages/event/eventCreate'));
const UserList = lazy(() => import('./pages/user/userList'))
const UserView = lazy(() => import('./pages/user/userView'));
const UserEdit = lazy(() => import('./pages/user/userView'));
const CreateUser = lazy(() => import('./pages/user/userCreate'));
const Gallery = lazy(() => import('./pages/event/gallery'));
const PublicGallery = lazy(() => import('./pages/event/galleryView'));
const Profile = lazy(() => import('./pages/user/userProfile'));
const ChangePassword = lazy(() => import('./pages/user/changePassword'));
const CopyRight = lazy(() => import('./pages/layout/copyRight'));
const PrivacyPolicy = lazy(() => import('./pages/layout/privacyPolicy'));

const App = () => {

    const dispatch = useDispatch();
    const host = location.host.split(".");
    const protocol = window.location.protocol;

    const auth = useSelector((state: any) => state.authenticate);
    const renewToken = useSelector((state: any) => state.renewToken);
    const status = useSelector((state: any) => state.statusReducer);
    const domainData = useSelector((state: any) => state.getClubByDomain.domainResult);

    const [domain, setDomain] = useState({
        clubDomain: (protocol === 'http:') ?
            (host.length > 1 ? host[0] : '') :
            (host.length > 2 ? host[0] : '')
    })
    const [session, setSession] = useState<boolean>(getIsValidSession(auth.authData))

    if (domainData) {
        if (domainData.themeType)
            document.documentElement.setAttribute('color-scheme', domainData.themeType);
        if (domainData.colorScheme)
            document.documentElement.style.setProperty('--_hue', domainData.colorScheme);
    }

    if (renewToken && renewToken.isSuccess && renewToken.isTokenInvalid) {
        localStorage.removeItem('AUTHDATA');
        window.location.href = '/login';
        renewToken.isTokenInvalid = false
    }

    useEffect(() => {
        setSession(getIsValidSession(auth.authData))
        if (domain.clubDomain !== '') {
            dispatch(clubActions.getClubDomain(domain))
        }
        if (auth?.authData) {
            if (session) {
                const currentTime = getCurrentTime()
                const renewalTime = getRenewalTimeBeforeExpiry(auth?.authData?.tokenExpery)
                if (currentTime > renewalTime)
                    dispatch(authActions.renewToken({ token: auth?.authData?.token, isMobile: false }))
            } else {
                localStorage.removeItem('AUTHDATA');
                window.location.href = '/login';
            }
        }

        if (host.length === 1)
            document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [])

    useEffect(() => {
        if (status && status.isShowSuccess && !status.isShowError) {
            toast.success(status.message);
            status.isShowSuccess = false;
            status.message = ""
        }
        if (status && !status.isShowSuccess && status.isShowError) {
            toast.error(status.message);
            status.isShowError = false;
            status.message = ""
        }
    }, [status])

    useEffect(() => {
        if (auth && auth.authData) {
            if (auth.authData.themeType)
                document.documentElement.setAttribute('color-scheme', auth.authData.themeType);
            if (auth.authData.colorScheme)
                document.documentElement.style.setProperty('--_hue', auth.authData.colorScheme);
        }
    }, [auth])

    useEffect(() => {
        if (auth.isLoggedIn && !getIsTest()) {
            const protocol = env.apiUrl.split(':')[0] === 'https' ? 'wss://' : 'ws://';
            const host = new URL(env.apiUrl).host;
            const webSocket = new WebSocket(`${protocol}${host}/notification`, auth?.authData?.token);

            webSocket.onopen = (e) => { };

            webSocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data >= 0) {
                    if (typeof data != 'object') {
                        dispatch({ type: userConstants.getNotificationCountSuccess, payload: data })
                    }
                }
            };
        }
    }, [auth.isLoggedIn, dispatch]);


    return (
        <div className="App">
            <ToastContainer theme="colored" />
            {auth.isLoggedIn && <IdleTimer />}
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route element={<PrivateRoute />}>
                            <Route path='club/*' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                <Route element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ClubList /></Suspense>} />
                                </Route>
                                <Route path='create' element={<RoleRoute roles={[Role.admin]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ClubCreate /></Suspense>} />
                                </Route>
                                <Route path='view' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ClubView /></Suspense>} />
                                </Route>
                                <Route path='edit' element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ClubEdit /></Suspense>} />
                                </Route>
                                <Route path='myclub' element={<RoleRoute roles={[Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><MyClub /></Suspense>} />
                                </Route>
                            </Route>
                            <Route path='event/*' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                <Route index element={<Suspense fallback={<Spinner />}><EventList /></Suspense>} />
                                <Route path="create" element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><CreateEvent /></Suspense>} />
                                </Route>
                                <Route path='view' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><EventView /></Suspense>} />
                                </Route>
                                <Route path='edit' element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><EventEdit /></Suspense>} />
                                </Route>
                                <Route path='gallery' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><Gallery /></Suspense>} />
                                </Route>
                            </Route>
                            <Route path='user/*' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                <Route element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><UserList /> </Suspense>} />
                                </Route>
                                <Route path='create' element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><CreateUser /> </Suspense>} />
                                </Route>
                                <Route path='view' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><UserView /> </Suspense>} />
                                </Route>
                                <Route path='edit' element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><UserEdit /> </Suspense>} />
                                </Route>
                                <Route path='profile/view' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><Profile /></Suspense>} />
                                </Route>
                                <Route path='passwordchange' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ChangePassword /></Suspense>} />
                                </Route>
                                <Route path='gallery' element={<Suspense fallback={<Spinner />}><PublicGallery /></Suspense>} />
                            </Route>
                        </Route>
                        <Route><Route path="galleries/*" element={<Suspense fallback={<Loader />}><PublicGallery /></Suspense>} /></Route>
                        <Route path="/" element={<Suspense fallback={<Spinner />}><Home /></Suspense>} />
                        {(protocol === 'http:' ? host.length > 1 : host.length > 2) ? (<>
                            <Route><Route path="/login" element={<Suspense fallback={<Loader />}><Login /></Suspense>} /></Route>
                            <Route><Route path='/join' element={<Suspense fallback={<Spinner />}><JoinClub /></Suspense>} /></Route>
                        </>
                        ) : (
                            <>
                                {auth && !auth.isLoggedIn && !session && (
                                    <Route>
                                        <Route><Route path='/register' element={<Suspense fallback={<Loader />}> <ClubCreate /></Suspense>} /></Route>
                                        <Route><Route path='/clubsetup' element={<Suspense fallback={<Loader />}> <ClubSetup /></Suspense>} /></Route>
                                        <Route><Route path='/registrationComplete' element={<Suspense fallback={<Loader />}> <RegistrationComplete /></Suspense>} /></Route>
                                    </Route>
                                )}
                            </>
                        )}
                        {auth && !auth.isLoggedIn && !session &&
                            <Route>
                                <Route><Route path="/members/rsvp" element={<Suspense fallback={<Loader />}><Rsvp /></Suspense>} /></Route>
                                <Route><Route path='/changepassword' element={<Suspense fallback={<Loader />}><ResetPassword /></Suspense>} /></Route>
                                <Route><Route path='/forgotpassword' element={<Suspense fallback={<Loader />}><ForgotPassword /></Suspense>} /></Route>
                                <Route><Route path='/privacypolicy' element={<Suspense fallback={<Loader />}><PrivacyPolicy /></Suspense>} /></Route>
                                <Route><Route path='/updatepassword' element={<Suspense fallback={<Loader />}> <PasswordSetup /></Suspense>} /></Route>
                                <Route><Route path="galleries/*" element={<Suspense fallback={<Loader />}><PublicGallery /></Suspense>} /></Route>
                            </Route>
                        }
                        <Route path='/copyright' element={<Suspense fallback={<Loader />}><CopyRight /></Suspense>} />
                        <Route path='/privacypolicy' element={<Suspense fallback={<Loader />}><PrivacyPolicy /></Suspense>} />
                        <Route path='/403' element={<Suspense fallback={<Loader />}><AccessDenied /></Suspense>} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </div>
    );
}

export default App;
